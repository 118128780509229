import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 80 80"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M73.7 32.43h-3.86v-7.68a3.78 3.78 0 0 0-3.8-3.75H42.8a3.8 3.8 0 0 0-2.74 1.15A3.8 3.8 0 0 0 37.33 21H14.08a3.767 3.767 0 0 0-3.79 3.75v7.68H6.42A2.42 2.42 0 0 0 4 34.86v17.47a2.42 2.42 0 0 0 2.42 2.4h5.92v2.57a1.73 1.73 0 0 0 1.74 1.73h6.84a1.73 1.73 0 0 0 1.7-1.73v-2.57h34.8v2.57a1.73 1.73 0 0 0 1.74 1.73H66a1.73 1.73 0 0 0 1.74-1.73v-2.57h5.92a2.41 2.41 0 0 0 2.42-2.4V34.86a2.41 2.41 0 0 0-2.38-2.43m-61.5 7.43a2.4 2.4 0 0 0-1.91 2.35v4a2.41 2.41 0 0 0 1.91 2.35v3.71a.3.3 0 0 1-.31.29H6.42a.3.3 0 0 1-.31-.29V34.86a.3.3 0 0 1 .31-.29h5.47a.3.3 0 0 1 .31.29zm8.34 17h-6.09v-2.13h6.09zm45.13 0h-6.05v-2.13h6.09zm-51.37-4.3v-3.89h24.39a2.4 2.4 0 0 0 1.37-.42 2.4 2.4 0 0 0 1.37.42h24.38v3.66a3 3 0 0 0 0 .28zm53.42-20.07a2.4 2.4 0 0 0-1.91 2.37v5H50.25a1.06 1.06 0 1 0 0 2.12h17.16a.3.3 0 0 1 .31.29v4a.3.3 0 0 1-.31.29h-26a.3.3 0 0 1-.31-.29v-4a.3.3 0 0 1 .194-.271.3.3 0 0 1 .116-.019h4.75a1.06 1.06 0 1 0 0-2.12h-4.73a2.4 2.4 0 0 0-1.37.43 2.4 2.4 0 0 0-1.37-.43H22.76a1.06 1.06 0 1 0 0 2.12h15.93a.3.3 0 0 1 .32.29v4a.3.3 0 0 1-.32.29h-26a.3.3 0 0 1-.31-.29v-4a.3.3 0 0 1 .194-.271.3.3 0 0 1 .116-.019h6.09a1.06 1.06 0 1 0 0-2.12h-4.46v-5a2.41 2.41 0 0 0-1.92-2.35v-7.77a1.66 1.66 0 0 1 1.68-1.63h23.25a1.66 1.66 0 0 1 1.68 1.63v12a1.06 1.06 0 1 0 2.11 0v-12a1.66 1.66 0 0 1 1.68-1.64h23.24a1.66 1.66 0 0 1 1.68 1.64zm6.29 19.84a.31.31 0 0 1-.31.29h-5.47a.31.31 0 0 1-.31-.29v-3.71a2.41 2.41 0 0 0 1.91-2.35v-4a2.4 2.4 0 0 0-1.91-2.41v-5a.3.3 0 0 1 .31-.28h5.47a.3.3 0 0 1 .31.28z" }, null, -1)
  ])))
}
export default { render: render }